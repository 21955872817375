import React, { useState, useEffect } from 'react';
import LollipopChart from './LollipopChart';
import LoadingSpinner from './LoadingSpinner';

const Commission = () => {
  const [commissions, setCommissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://chaptude.com/turnaround')
      .then(res => res.json())
      .then(data => {
        setCommissions(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching commissions:', err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <h2>Commission Turnaround Times</h2>
      <LollipopChart data={commissions} />
    </div>
  );
};

export default Commission;