import React from 'react';

const Home = () => {
  return (
    <div>
      <h1>Welcome to my website</h1>
      <p>I draw <b>big, fat, <u>POOOOOOOPY</u></b> diapers here. As well as anything else that strikes my fancy.</p>
      <p>It's still a work in progress, alas ;;;;;;&gt; w&gt; </p>
      <hr></hr>
      <p>The gallery is now up, btw</p>
      <p>I'm planning on having it be:</p>
      <ul>
        <li>Every* drawing I uploaded, in the biggest resolution I'm able to find</li>
        <li>Many previously private drawings</li>
        <li>Irrelevant drawings that I believe deserve to be preserved</li>
        *At least the ones that aren't lost media
      </ul>
      <p>Unfortunately, it takes quite a bit to manually tag and upload every drawing, so.. it'll be a while before it's completed.</p>
      <hr></hr>
      <p>Last updated <span style={{ color: '#E5A3FF' }}>Jan 26, 2025</span> </p>
      <p><span style={{ color: '#E5A3FF' }}>Finalized the second half of 2016. Handful of drawings added throughout</span></p>
      <p>Commission sheet and links are now up. (They are NOT optimized for mobile)</p>
      <hr></hr>
      <p>Instead of drip-feeding drawings, I decided to publicize everything on the server so far*</p>
      <p>*Still a WIP, still need a handful of drawings (like 20~ish), and they have no tags, desc, timestamp, <span style={{ color: '#FF4081' }}>low resolution</span>, etc....</p>
      <hr></hr>
      <p>
  My <a href="https://subscribestar.adult/chaptude" style={{color: '#FF4081'}} target="_blank" rel="noopener">SubscribeStar</a> is up!
</p>
    </div>
  );
};

export default Home;
