import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import './Gallery.css';

const Gallery = ({ trackEvent }) => {
  const {
    drawingsToShow = [],
    activeTags = [],
    tagLogicAnd = false,
    hideUnmatched = false,
    loading,
    toggleTag,
    totalNonExcluded = 0,
    matchCount = 0,
  } = useOutletContext() || {};

  const [modalData, setModalData] = useState(null);
  const [collapsedYears, setCollapsedYears] = useState({});
  const [yearOrderAsc, setYearOrderAsc] = useState(false);
  const [areAllCollapsed, setAreAllCollapsed] = useState(true);
  const [isAdultConfirmed, setIsAdultConfirmed] = useState(
    () => localStorage.getItem('isAdultConfirmed') === 'true'
  );
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  useEffect(() => {
    if (!drawingsToShow.length) return;
    const params = new URLSearchParams(window.location.search);
    const imageId = params.get('image');
    if (imageId) {
      const maybeId = parseInt(imageId, 10);
      if (!isNaN(maybeId)) {
        const found = drawingsToShow.find((dw) => dw.id === maybeId);
        if (found) setModalData(found);
      }
    }
  }, [drawingsToShow]);

  // Group drawings by year
  const drawingsByYear = drawingsToShow.reduce((acc, dw) => {
    const year = new Date(dw.draw_date).getFullYear();
    if (!acc[year]) acc[year] = [];
    acc[year].push(dw);
    return acc;
  }, {});

  const sortedYears = Object.keys(drawingsByYear).sort((a, b) =>
    yearOrderAsc ? a - b : b - a
  );

  const toggleExpandCollapse = () => {
    const newVal = !areAllCollapsed;
    const newObj = {};
    Object.keys(drawingsByYear).forEach((yr) => {
      newObj[yr] = newVal;
    });
    setCollapsedYears(newObj);
    setAreAllCollapsed(newVal);
  };

  // Dimming logic: only if in dimming mode and activeTags exist.
  const isDimmed = (dw) => {
    if (hideUnmatched || !activeTags.length) return false;
    return tagLogicAnd
      ? !activeTags.every((t) => dw.tags.includes(t))
      : !dw.tags.some((t) => activeTags.includes(t));
  };

  const handleImageClick = (dw) => {
    setModalData(dw);
    const params = new URLSearchParams(window.location.search);
    params.set('image', dw.id);
    window.history.replaceState(
      null,
      '',
      window.location.pathname + '?' + params.toString()
    );
    if (trackEvent && dw.id) {
      trackEvent('image_view', window.location.href, '', { image_id: dw.id });
    }
  };

  const handleCloseModal = () => {
    setModalData(null);
    const params = new URLSearchParams(window.location.search);
    params.delete('image');
    window.history.replaceState(null, '', window.location.pathname + '?' + params.toString());
  };

  if (loading) return <LoadingSpinner />;

  if (!isAdultConfirmed) {
    return (
      <div
        className="gallery-modal-overlay"
        style={{
          backgroundColor: 'rgba(0,0,0,0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <div
          className="gallery-modal-content"
          style={{
            background: '#222',
            padding: '2rem',
            borderRadius: '8px',
            maxWidth: '400px',
            width: '90%',
          }}
        >
          <h2>Content Advisory</h2>
          <p>You must be 18+ to view this gallery.</p>
          <button
            className="adult-confirm-button"
            onClick={() => {
              setIsAdultConfirmed(true);
              localStorage.setItem('isAdultConfirmed', 'true');
            }}
            style={{
              backgroundColor: '#4caf50',
              border: 'none',
              padding: '0.5rem 1rem',
              fontSize: '1rem',
              cursor: 'pointer',
              borderRadius: '4px',
            }}
          >
            I am a legal adult
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="gallery-content">
      <h1 className="gallery-title">Gallery</h1>
      <div className="gallery-controls">
        <button onClick={() => setYearOrderAsc(!yearOrderAsc)}>
          Order: {yearOrderAsc ? 'Ascending' : 'Descending'}
        </button>
        <button onClick={toggleExpandCollapse}>
          {areAllCollapsed ? 'Expand All' : 'Collapse All'}
        </button>
      </div>

      {Object.keys(drawingsByYear).length === 0 ? (
        <p>No drawings found.</p>
      ) : (
        sortedYears.map((year) => {
          const yearDrawings = [...drawingsByYear[year]].sort((a, b) => {
            const dateA = new Date(a.draw_date);
            const dateB = new Date(b.draw_date);
            return yearOrderAsc ? dateA - dateB : dateB - dateA;
          });

          return (
            <div key={year} className="year-group">
              <h2
                onClick={() =>
                  setCollapsedYears((prev) => ({ ...prev, [year]: !prev[year] }))
                }
                className={`year-title ${collapsedYears[year] ? 'collapsed' : ''}`}
              >
                {year} {collapsedYears[year] ? '▸' : '▾'}
              </h2>
              {!collapsedYears[year] && (
                <div className="gallery-grid">
                  {yearDrawings.map((dw) => {
                    const dim = isDimmed(dw);
                    const isPending = dw.tags.includes('pending');
                    return (
                      <div
                        key={dw.id}
                        className={`gallery-item ${dim ? 'dimmed' : ''} ${
                          isPending ? 'pending-border' : ''
                        }`}
                      >
                        <img
                          className="gallery-thumbnail"
                          src={`https://chaptude.com/thumbnails/${year}/${dw.file_name}`}
                          alt={dw.description || 'Drawing'}
                          onClick={() => handleImageClick(dw)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })
      )}
      {modalData && (
        <div className="gallery-modal-overlay" onClick={handleCloseModal}>
          <div
            className="gallery-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="modal-close-button" onClick={handleCloseModal}>
              ✖
            </button>
            <div className="modal-top">
              <img
                className="gallery-modal-image"
                src={`https://chaptude.com/gallery/${new Date(
                  modalData.draw_date
                ).getFullYear()}/${modalData.file_name}`}
                alt={modalData.description || 'Drawing'}
              />
              <div className="modal-metadata">
                <h3>{modalData.file_name}</h3>
                <div className="modal-description">
                  {(modalData.description || '')
                    .split('\n')
                    .map((line, idx) => (line.trim() ? <p key={idx}>{line}</p> : <br key={idx} />))}
                </div>
                <div className="tags-section">
                  {modalData.tags.map((tag) => (
                    <span
                      key={tag}
                      className={`modal-tag ${activeTags.includes(tag) ? 'active' : ''}`}
                      onClick={() => toggleTag && toggleTag(tag)}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <p>
                  {new Date(modalData.draw_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
                <div className="modal-actions">
                  <button
                    className="gallery-modal-download"
                    onClick={() => {
                      if (trackEvent && modalData.id) {
                        trackEvent('image_download', window.location.href, '', {
                          image_id: modalData.id,
                        });
                      }
                      const link = document.createElement('a');
                      link.href = `https://chaptude.com/gallery/${new Date(
                        modalData.draw_date
                      ).getFullYear()}/${modalData.file_name}`;
                      link.download = modalData.file_name;
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                  >
                    Download
                  </button>
                  <button
                    className="gallery-modal-open-tab"
                    onClick={() => {
                      if (trackEvent && modalData.id) {
                        trackEvent(
                          'image_open_new_tab',
                          window.location.href,
                          '',
                          { image_id: modalData.id }
                        );
                      }
                      window.open(
                        `https://chaptude.com/gallery/${new Date(
                          modalData.draw_date
                        ).getFullYear()}/${modalData.file_name}`,
                        '_blank'
                      );
                    }}
                  >
                    Open in New Tab
                  </button>
                  <button
                    className="gallery-modal-copy-link"
                    style={{
                      backgroundColor: copied ? 'green' : '#444',
                    }}
                    onClick={() => {
                      const currentURL = window.location.href;
                      navigator.clipboard.writeText(currentURL);
                      if (trackEvent && modalData.id) {
                        trackEvent('image_copy_link', currentURL, '', {
                          image_id: modalData.id,
                        });
                      }
                      setCopied(true);
                    }}
                  >
                    {copied ? 'Copied!' : 'Copy Link'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
