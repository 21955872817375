import React, { useState, useEffect } from 'react';
import LollipopChart from './LollipopChart';
import LoadingSpinner from './LoadingSpinner';
import './Links.css';

const Commission = () => {
  const [commissions, setCommissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://chaptude.com/turnaround')
      .then(res => res.json())
      .then(data => {
        setCommissions(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching commissions:', err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <h2>Commission Turnaround Times</h2>
      <LollipopChart data={commissions} />
      <div style={{ 
        display: 'flex', 
        alignItems: 'flex-start',
        gap: '20px',
        marginTop: '20px'
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{
            backgroundColor: '#333',
            padding: '15px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '2px solid #FF4081'
          }}>
            <div style={{ color: '#FF4081', fontSize: '20px', fontWeight: 'bold' }}>
              Commissions are closed
            </div>
            <div style={{ color: '#fff', marginTop: '5px' }}>
              Next opening: Feb. 1-ish
            </div>
          </div>
          <a
            href="https://docs.google.com/forms/d/1rC7tHI4L1qUGtikL9wPlYLeN0gRUeIpTNW_baY0k0ZQ/"
            target="_blank"
            rel="noopener noreferrer"
            className="link-button"
            style={{
              backgroundColor: '#FF4081',
              color: 'white',
              minWidth: '200px',
              margin: '0'
            }}
          >
            <span className="button-text">Sign-up Sheet</span>
          </a>
          <a
            href="https://trello.com/b/lOx9ieLr/chaps-commission-queue"
            target="_blank"
            rel="noopener noreferrer"
            className="link-button"
            style={{
              backgroundColor: '#0079BF',
              color: 'white',
              minWidth: '200px',
              margin: '0'
            }}
          >
            <span className="button-text">Commission Queue</span>
          </a>
        </div>
        <img 
          src="https://chaptude.com/resources/Oct_sheet.png"
          alt="Commission Chart PNG"
          style={{ 
            width: '70%',
            maxWidth: '100vw',
            height: 'auto',
            display: 'block',
            margin: '0'
          }}
        />
      </div>
    </div>
  );
};

export default Commission;