import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const LoadingSpinner = () => {
  const ref = useRef(null);

  useEffect(() => {
    const svg = d3.select(ref.current)
      .attr('width', 100)
      .attr('height', 100)
      .append('g')
      .attr('transform', 'translate(50,50)');

    const arc = d3.arc()
      .innerRadius(20)
      .outerRadius(40)
      .startAngle(0);

    const arcData = Array.from({ length: 6 }, (_, i) => i * (Math.PI / 3));

    const paths = svg.selectAll('path')
      .data(arcData)
      .enter()
      .append('path')
      .attr('d', d => arc({ startAngle: d, endAngle: d + Math.PI / 6 }))
      .attr('fill', '#C62E51')
      .style('opacity', (_, i) => 1 - i * 0.15);

    paths.transition()
      .duration(800)
      .ease(d3.easeLinear)
      .attrTween('transform', () => {
        return d3.interpolateString('rotate(0)', 'rotate(360)');
      })
      .on('end', function repeat() {
        d3.select(this)
          .transition()
          .duration(800)
          .ease(d3.easeLinear)
          .attrTween('transform', () => {
            return d3.interpolateString('rotate(0)', 'rotate(360)');
          })
          .on('end', repeat);
      });
  }, []);

  return <svg ref={ref}></svg>;
};

export default LoadingSpinner;
