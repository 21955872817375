import React, { useState, useEffect } from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import './Layout.css';

const Layout = () => {
  const location = useLocation();

  // All drawings from the server
  const [allDrawings, setAllDrawings] = useState([]);
  // Tag frequency map
  const [tagCounts, setTagCounts] = useState({});
  // Mobile sidebar open?
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Active tags for filtering
  const [activeTags, setActiveTags] = useState([]);
  // Exclude tags => remove from view (e.g., 'pending')
  const [excludeTags, setExcludeTags] = useState([]);
  // AND vs ANY logic
  const [tagLogicAnd, setTagLogicAnd] = useState(false);

  // When true: non-matching drawings are removed; when false, they are dimmed.
  const [hideUnmatched, setHideUnmatched] = useState(() => {
    const saved = localStorage.getItem('hideUnmatched');
    return saved === 'true';
  });

  // Tag settings panel (for gallery view)
  const [showTagSettings, setShowTagSettings] = useState(false);
  // Search text for tags filtering
  const [tagSearch, setTagSearch] = useState('');

  // Loading state
  const [loading, setLoading] = useState(true);

  // ========= Restoring from localStorage =========
  useEffect(() => {
    const savedActiveTags = localStorage.getItem('activeTags');
    const savedExcludeTags = localStorage.getItem('excludeTags');
    const savedTagLogicAnd = localStorage.getItem('tagLogicAnd');
    if (savedActiveTags) setActiveTags(JSON.parse(savedActiveTags));
    if (savedExcludeTags)
      setExcludeTags(JSON.parse(savedExcludeTags).map(tag => tag.toLowerCase()));
    if (savedTagLogicAnd) setTagLogicAnd(savedTagLogicAnd === 'true');
  }, []);

  useEffect(() => {
    localStorage.setItem('activeTags', JSON.stringify(activeTags));
  }, [activeTags]);

  useEffect(() => {
    localStorage.setItem('excludeTags', JSON.stringify(excludeTags));
  }, [excludeTags]);

  useEffect(() => {
    localStorage.setItem('tagLogicAnd', tagLogicAnd.toString());
  }, [tagLogicAnd]);

  useEffect(() => {
    localStorage.setItem('hideUnmatched', hideUnmatched.toString());
  }, [hideUnmatched]);

  // ========= Fetch all drawings =========
  useEffect(() => {
    fetch('https://chaptude.com/list-drawings')
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // Convert tags to lowercase for consistency.
          const drawingsWithLowercaseTags = data.drawings.map((dw) => ({
            ...dw,
            tags: dw.tags.map((tag) => tag.toLowerCase()),
          }));
          setAllDrawings(drawingsWithLowercaseTags);
          const counts = drawingsWithLowercaseTags.reduce((acc, dw) => {
            dw.tags.forEach((t) => {
              acc[t] = (acc[t] || 0) + 1;
            });
            return acc;
          }, {});
          setTagCounts(counts);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching drawings:', err);
        setLoading(false);
      });
  }, []);

  // ========= Mobile sidebar and tag panel =========
  const toggleSidebar = () => setSidebarOpen(prev => !prev);
  useEffect(() => {
    setSidebarOpen(false);
    if (location.pathname === '/gallery') setShowTagSettings(true);
  }, [location.pathname]);

  // ========= Tag toggling =========
  const toggleTag = (tag) => {
    setActiveTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const toggleExcludeTag = (tag) => {
    const lowerTag = tag.toLowerCase();
    setExcludeTags((prev) =>
      prev.includes(lowerTag)
        ? prev.filter((t) => t !== lowerTag)
        : [...prev, lowerTag]
    );
  };

  const toggleTagLogic = () => setTagLogicAnd(!tagLogicAnd);

  // ========= Filtering =========
  // Remove drawings with any exclude tag (case-insensitive)
  const nonExcludedDrawings = allDrawings.filter((dw) =>
    !excludeTags.some((ex) =>
      dw.tags.some((tag) => tag.toLowerCase() === ex.toLowerCase())
    )
  );

  // Check if a drawing matches active tags
  const drawingMatchesTags = (dw) => {
    if (!activeTags.length) return true;
    return tagLogicAnd
      ? activeTags.every((t) => dw.tags.includes(t))
      : dw.tags.some((t) => activeTags.includes(t));
  };

  // finalDrawings: in exclusion mode (hideUnmatched true) only drawings matching active tags are rendered.
  // In dimming mode, all non-excluded drawings are rendered.
  const finalDrawings = hideUnmatched
    ? nonExcludedDrawings.filter(drawingMatchesTags)
    : nonExcludedDrawings;

  // ========= Counting =========
  // total after exclusion filter
  const totalNonExcluded = nonExcludedDrawings.length;
  // count of drawings that match active tags
  const matchCount = nonExcludedDrawings.filter(drawingMatchesTags).length;

  return (
    <div className="layout">
      {/* HEADER */}
      <header className="header">
        {!sidebarOpen && (
          <button className="hamburger-button" onClick={toggleSidebar}>
            ☰
          </button>
        )}
        <h1 className="site-title">Chaptude</h1>
        {sidebarOpen && (
          <button className="mobile-close-button" onClick={toggleSidebar}>
            ✖
          </button>
        )}
      </header>
      <div
        className={`sidebar-overlay ${sidebarOpen ? 'open' : ''}`}
        onClick={() => setSidebarOpen(false)}
      />
      {/* SIDEBAR */}
      <aside className={`sidebar ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <h2 className="title">
          <NavLink to="/" className="nav-item-title">
            Chaptude
          </NavLink>
        </h2>
        <nav className="nav-section">
          <NavLink to="/links" className="nav-item" activeClassName="active">
            Links
          </NavLink>
          <NavLink to="/gallery" className="nav-item" activeClassName="active">
            Gallery
          </NavLink>
          <NavLink to="/commission" className="nav-item" activeClassName="active">
            Commission
          </NavLink>
        </nav>

        {location.pathname === '/gallery' && (
          <div className="tags-container">
            <p style={{ margin: '0.5rem 0', fontStyle: 'italic' }}>
              {hideUnmatched
                ? `Displaying ${matchCount} of ${matchCount} drawings`
                : `Displaying ${matchCount} of ${totalNonExcluded} drawings`}
            </p>
            <div className="tag-settings">
              <h3
                onClick={() => setShowTagSettings(!showTagSettings)}
                className="tag-settings-header"
              >
                Tag Settings {showTagSettings ? '▾' : '▸'}
              </h3>
              {showTagSettings && (
                <div className="tag-settings-controls">
                  <div className="tag-matching-logic">
                    <button className="logic-button" onClick={toggleTagLogic}>
                      {tagLogicAnd ? 'Match All' : 'Match Any'}
                    </button>
                  </div>
                  <div style={{ marginTop: '0.5rem' }}>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={hideUnmatched}
                        onChange={(e) => setHideUnmatched(e.target.checked)}
                        style={{ marginRight: '0.25rem' }}
                      />
                      Exclude / Dim
                    </label>
                  </div>
                  <div
                    className="hide-tags-section"
                    style={{ marginTop: '1rem' }}
                  >
                    {['messy', 'pending'].map((tag) => (
                      <label
                        key={tag}
                        className="hide-checkbox"
                        data-tag={tag}
                        style={{ color: 'rgb(227, 209, 51)' }}
                      >
                        <input
                          type="checkbox"
                          checked={excludeTags.includes(tag.toLowerCase())}
                          onChange={() => toggleExcludeTag(tag)}
                        />
                        {`Exclude ${tag}`}
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <h3>Tags</h3>
            <div style={{ position: 'relative', marginBottom: '0.5rem' }}>
              <input
                type="text"
                placeholder="Search tags..."
                value={tagSearch}
                onChange={(e) => setTagSearch(e.target.value.toLowerCase())}
                style={{ padding: '0.25rem', width: '80%' }}
              />
              {tagSearch && (
                <button
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    padding: '0 0.5rem',
                    background: 'transparent',
                    color: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setTagSearch('')}
                >
                  ✖
                </button>
              )}
            </div>
            {Object.entries(tagCounts)
              .filter(([tag]) => tag.toLowerCase().includes(tagSearch))
              .sort((a, b) => b[1] - a[1])
              .map(([tag, count]) => (
                <button
                  key={tag}
                  className={`tag-button ${
                    activeTags.includes(tag) ? 'active' : ''
                  }`}
                  onClick={() => toggleTag(tag)}
                >
                  {tag} ({count})
                </button>
              ))}
          </div>
        )}
      </aside>
      {/* MAIN CONTENT */}
      <main className="content">
        <Outlet
          context={{
            drawingsToShow: finalDrawings,
            activeTags,
            tagLogicAnd,
            hideUnmatched,
            toggleTag,
            loading,
            totalNonExcluded,
            matchCount,
          }}
        />
      </main>
    </div>
  );
};

export default Layout;
