import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import Links from './Links';
import Gallery from './Gallery';
import Home from './Home';
import Commission from './Commission'
import NotFound from './NotFound'; 

function App() {
  const generateClientId = () => {
    const existingClientId = localStorage.getItem('client_id');
    if (existingClientId) {
      return existingClientId;
    } else {
      const clientId = Math.random().toString(36).substring(2, 15); // Random client ID
      localStorage.setItem('client_id', clientId);
      return clientId;
    }
  };

  // 2. Track Event Function
  const trackEvent = (eventName, pageLocation, linkUrl = '', extraData = {}) => {
    const clientId = generateClientId();

    fetch('/track-event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        client_id: clientId,
        event_name: eventName,
        page_location: pageLocation,
        link_url: linkUrl,
        ...extraData
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Server error ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Event tracked successfully:', data);
      })
      .catch(error => {
        console.error('Error tracking event:', error);
      });
  };

  // 3. Custom Hook to Track Page Views
  function usePageViews() {
    const location = useLocation();
  
    useEffect(() => {
      // Only track page_view if pathname changed, not just the #hash
      const unlisten = () => {
      };
      if (location.hash) {
        // skip
      } else {
        trackEvent('page_view', window.location.href);
      }
  
      return unlisten;
    }, [location.pathname, location.search]);
  }

  usePageViews(); // Invoke the custom hook

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="links" element={<Links trackEvent={trackEvent} />} />
        <Route path="gallery" element={<Gallery trackEvent={trackEvent} />} />
        <Route path="commission" element={<Commission />} /> 
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
