import React from 'react';
import './Links.css';

const links = [
  {
    name: 'Bluesky',
    url: 'https://bsky.app/profile/chaptude.bsky.social',
    color: '#2955D7',
    textColor: 'white',
    icon: null
  },
  {
    name: 'Discord Server',
    url: 'https://discord.gg/k6dWSJc3wN',
    color: '#5965ED',
    textColor: 'white',
    icon: null
  },
  {
    name: 'FurAffinity',
    url: 'https://www.furaffinity.net/user/comediancat/',
    color: '#FAAF3A',
    textColor: 'black',
    icon: null
  },
  {
    name: 'DeviantArt',
    url: 'https://www.deviantart.com/chaptude',
    color: '#00D4B1',
    textColor: 'black',
    icon: null
  },
  {
    name: 'Itaku',
    url: 'https://itaku.ee/profile/chaptude',
    color: '#FFEA44',
    textColor: 'black',
    icon: null
  },
  {
    name: 'Telegram Channel',
    url: 'https://t.me/chaptude',
    color: '#32A4E6',
    textColor: 'black',
    icon: null
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/Chaptude',
    color: '#50ABF0',
    textColor: 'black',
    icon: null
  },
  {
    name: 'Weasly',
    url: 'https://www.weasyl.com/~chaptude',
    color: '#940000',
    textColor: 'white',
    icon: null
  }
];

// Updated: SubStar button is now pink (#FF4081)
const supportLink = {
  name: 'SubscribeStar',
  url: 'https://subscribestar.adult/chaptude',
  color: '#FF4081',
  textColor: 'white',
  special: true
};

const Links = ({ trackEvent }) => {
  const handleClick = (link) => {
    trackEvent('click', window.location.href, link.url);
  };

  return (
    <div className="links-container">
      <header className="link_header">
        <h2>Connect & Support Me</h2>
        <p className="subtitle">Join me on these platforms and consider supporting my work!</p>
      </header>

      <div className="links-grid">
        {links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            className="link-button"
            style={{
              backgroundColor: link.color,
              color: link.textColor,
              outlineColor: link.color
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.name}
          </a>
        ))}
      </div>

      {/* Small divider */}
      <hr className="divider" />

      {/* Support Section */}
      <div className="support-section">
        <h2 className="support-heading">Support Me</h2>
        <a
          href={supportLink.url}
          target="_blank"
          rel="noopener noreferrer"
          className="support-button"
          style={{ backgroundColor: supportLink.color, color: supportLink.textColor }}
          onClick={() => handleClick(supportLink)}
          aria-label={`Support via ${supportLink.name}`}
        >
          {supportLink.name}
        </a>
      </div>
    </div>
  );
};

export default Links;
